@import "https://fonts.googleapis.com/css2?family=Archivo+Black&family=Manrope:wght@200;300;400;700&display=swap";
.display-mobile, .display-tablet, .display-desktop-tablet, .display-desktop, .display-large, .display-extra-large, .display-normal, .display-retina {
  display: none;
}

@media only screen and (max-width: 767px) {
  .display-mobile {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .display-tablet {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .display-desktop-tablet {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .display-desktop {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  .display-large {
    display: block;
  }
}

@media only screen and (min-width: 1728px) {
  .display-extra-large {
    display: block;
  }
}

.display-normal {
  display: block;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dppx) {
  .display-retina {
    display: block;
  }

  .display-normal {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

body {
  color: #34495e;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin-top: 0;
  font-family: Archivo Black, sans-serif;
  line-height: .95;
}

h1 span.stroke, h2 span.stroke, h3 span.stroke, h4 span.stroke, h5 span.stroke, h6 span.stroke {
  color: #0000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #34495e;
}

h1 span.stroke-primary, h2 span.stroke-primary, h3 span.stroke-primary, h4 span.stroke-primary, h5 span.stroke-primary, h6 span.stroke-primary {
  color: #0000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #41b883;
}

h1 {
  font-size: 2.25rem;
}

h1 br {
  display: none;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h1 br {
    display: block;
  }
}

p {
  margin-top: 0;
}

a {
  color: #41b883;
  font-weight: 700;
  text-decoration: none;
}

.header {
  height: 100px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  display: flex;
  position: sticky;
  top: 0;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 2rem 3rem;
  }
}

.header--logo {
  width: 150px;
}

@media only screen and (min-width: 768px) {
  .header--logo {
    width: 175px;
  }
}

.main {
  height: calc(100vh - 200px);
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .main {
    padding: 0 3rem;
  }
}

.main p {
  font-size: 1.25rem;
}

@media only screen and (min-width: 768px) {
  .main p {
    font-size: 1.5rem;
  }
}

.main--counter {
  display: flex;
}

.main--counter__item {
  color: #fff;
  width: 100px;
  background: #41b883;
  flex-direction: column;
  align-items: center;
  margin-right: .5rem;
  padding: 1rem 0;
  font-size: 1.15rem;
  font-weight: 700;
  display: flex;
}

.main--counter__item:last-of-type {
  margin-right: 0;
}

@media only screen and (min-width: 768px) {
  .main--counter__item {
    margin-right: 1rem;
  }
}

.main--counter__item span {
  margin-bottom: .15rem;
}

.footer {
  background: #fafafa;
  justify-self: flex-end;
}

.footer--top {
  flex-direction: column;
  padding: 2rem 1rem;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .footer--top {
    flex-direction: row;
    padding: 3rem;
  }
}

.footer--top__col {
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
  display: flex;
}

.footer--top__col:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .footer--top__col {
    width: 25%;
    margin-bottom: 0;
  }
}

.footer--top__contact {
  flex-direction: column;
  display: flex;
}

.footer--top__logo {
  width: 35px;
  margin-bottom: .5rem;
}

.footer--top p {
  margin: 0;
}

.footer--top b {
  margin-bottom: .5rem;
}

.footer--top ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer--top ul li {
  margin-bottom: .25rem;
}

.footer--bottom {
  color: #a1c6db;
  text-align: center;
  background: #34495e;
  padding: .75rem 1rem;
  font-size: .8rem;
}

@media only screen and (min-width: 768px) {
  .footer--bottom {
    padding: .75rem 3rem;
  }
}

.footer--bottom p {
  margin: 0;
}

.btn {
  border: 2px solid #41b883;
  padding: .5rem 2rem;
  transition: all .3s;
}

.btn:hover {
  color: #fff;
  background: #41b883;
}

.color-primary {
  color: #41b883;
}

.bold {
  font-weight: 700;
}

.mb-0-25 {
  margin-bottom: .25rem !important;
}

.mb-0-5 {
  margin-bottom: .5rem !important;
}

/*# sourceMappingURL=index.6e597dff.css.map */
