$phabletWidth: 480px;
$tabletWidth: 768px;
$desktopWidth: 1024px;
$largeWidth: 1440px;
$extraLargeWidth: 1728px;
$fullHd: 1920px;

@mixin phone {

  @media only screen and (max-width: $phabletWidth - 1px) {

    @content;

  }

}

@mixin mobile {

  @media only screen and (max-width: $tabletWidth - 1px) {

    @content;

  }

}

@mixin phablet {

  @media only screen and (min-width: $phabletWidth) and (max-width: $desktopWidth - 1px) {

    @content;

  }

}

@mixin tablet {

  @media only screen and (min-width: $tabletWidth) and (max-width: $desktopWidth - 1px) {

    @content;

  }

}

@mixin desktop {

  @media only screen and (min-width: $desktopWidth) {

    @content;

  }

}

@mixin not-desktop {

  @media only screen and (max-width: $desktopWidth - 1) {

    @content;

  }

}

@mixin desktop-tablet {

  @media only screen and (min-width: $tabletWidth) {

    @content;

  }

}

@mixin large {

  @media only screen and (min-width : $largeWidth) {

    @content;

  }

}

@mixin not-large {

  @media only screen and (max-width : $largeWidth - 1) {

    @content;

  }

}

@mixin extra-large {

  @media only screen and (min-width : $extraLargeWidth) {

    @content;

  }

}

@mixin not-extra-large {

  @media only screen and (max-width : $extraLargeWidth - 1) {

    @content;

  }

}

@mixin full-hd {

  @media only screen and (min-width : $fullHd) {

    @content;

  }

}

@mixin not-full-hd {

  @media only screen and (max-width : $fullHd - 1) {

    @content;

  }

}

@mixin retina-selector {

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dppx) {

    @content;

  }

}

@mixin portrait {
  @media only screen and (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

.display-mobile,
.display-tablet,
.display-desktop-tablet,
.display-desktop,
.display-large,
.display-extra-large,
.display-normal,
.display-retina {

  display: none;

}

@include mobile {

  .display-mobile {

    display: block;

  }

}

@include tablet {

  .display-tablet {

    display: block;

  }

}

@include desktop-tablet {

  .display-desktop-tablet {
    display: block;
  }

}

@include desktop {

  .display-desktop {

    display: block;

  }

}

@include large {

  .display-large {

    display: block;

  }

}

@include extra-large {

  .display-extra-large {

    display: block;

  }

}

.display-normal {

  display: block;

}

@include retina-selector {

  .display-retina {

    display: block;

  }

  .display-normal {

    display: none;

  }

}
