@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Manrope:wght@200;300;400;700&display=swap');
@import "styles/responsive";

$primary: #41B883;
$primarySecond: #45D997;
$light: #A1C6DB;
$dark: #34495E;
$gray: #fafafa;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: $dark;
  display: flex;
  flex-direction: column;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 14px;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Archivo Black', sans-serif;
  line-height: .95;
  margin-top: 0;
  text-transform: uppercase;
  span.stroke {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $dark;
  }
  span.stroke-primary {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $primary;
  }
}

h1 {
  font-size: 2.25rem;
  br {
    display: none;
  }
  @include desktop-tablet {
    font-size: 3rem;
    br {
      display: block;
    }
  }
}

p {
  margin-top: 0;
}

a {
  color: $primary;
  text-decoration: none;
  font-weight: 700;
}

// HEADER 

.header {
  background: #fff;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 2rem 1rem;
  @include desktop-tablet {
    padding: 2rem 3rem;
  }
  &--logo {
    width: 150px;
    @include desktop-tablet {
      width: 175px;
    }
  }
}

// MAIN 

.main {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  @include desktop-tablet {
    padding: 0 3rem;
  }
  p {
    font-size: 1.25rem;
    @include desktop-tablet {
      font-size: 1.5rem;
    }
  }
  &--counter {
    display: flex;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: .5rem;
      padding: 1rem 0;
      background: $primary;
      color: #fff;
      font-weight: 700;
      font-size: 1.15rem;
      width: 100px;
      &:last-of-type {
        margin-right: 0;
      }
      @include desktop-tablet {
        margin-right: 1rem;
      }
      span {
        margin-bottom: .15rem;
      }
    }
  }
}

// FOOTER 

.footer {
  background: $gray;
  justify-self: flex-end;
  &--top {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    @include desktop-tablet {
      flex-direction: row;
      padding: 3rem;
    }
    &__col {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      @include desktop-tablet {
        width: 25%;
        margin-bottom: 0;
      }
    }
    &__contact {
      display: flex;
      flex-direction: column;
    }
    &__logo {
      width: 35px;
      margin-bottom: .5rem;
    }
    p {
      margin: 0;
    }
    b {
      margin-bottom: .5rem;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: .25rem;
      }
    }
  }
  &--bottom {
    background: $dark;
    padding: .75rem 1rem;
    color: $light;
    font-size: .8rem;
    text-align: center;
    @include desktop-tablet {
      padding: .75rem 3rem;
    }
    p {
      margin: 0;
    }
  }
}

// HELPERS 

.btn {
  border: 2px solid $primary;
  padding: .5rem 2rem;
  transition: all .3s ease;
  &:hover {
    background: $primary;
    color: #fff;
  }
}

.color-primary {
  color: $primary;
}

.bold {
  font-weight: 700;
}

.mb-0-25 {
  margin-bottom: .25rem !important;
}

.mb-0-5 {
  margin-bottom: .5rem !important;
}
    